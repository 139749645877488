import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import { Outlet, Route } from "react-router-dom";
import { RequireAuthz } from "../auth";
import { ShowIfAuthorized } from "../auth/ShowIfAuthorized";
import { NavigationTab, NavigationTabs } from "../components";
import { RouterErrorElement } from "../components/ErrorBoundary";
import { SentryRoutes } from "../sentry";
import { RegionsTable } from "./Regions/RegionsTable";
import { ReservedInstancesTable } from "./ReservedInstances/ReservedInstancesTable";
import { VirtualMachinesTable } from "./VirtualMachines/VirtualMachinesTable";
import { CloudRenderingPolicies } from "./policies";

export function CloudRendering() {
  return (
    <SentryRoutes>
      <Route
        path="/"
        element={
          <Box width={["max", "max", "100%"]}>
            <Stack spacing={6}>
              <Box>
                <Heading size="lg">Cloud Rendering</Heading>
                <Text>
                  Manage your organization&apos;s Cloud Rendering settings.
                </Text>
              </Box>
              <NavigationTabs>
                <NavigationTab to="regions" label="Regions" />
                <ShowIfAuthorized
                  requiredPermissions={[
                    "core.remote_rendering.change_reserved_instances",
                  ]}
                >
                  <NavigationTab
                    to="reserved-instances"
                    label="Reserved Instances"
                  />
                </ShowIfAuthorized>
                <ShowIfAuthorized
                  requiredPermissions={[
                    "core.remote_rendering.list_virtual_machines",
                  ]}
                >
                  <NavigationTab
                    to="virtual-machines"
                    label="Virtual Machines"
                  />
                </ShowIfAuthorized>
                <ShowIfAuthorized
                  requiredPermissions={["core.sessions.change_policies"]}
                >
                  <NavigationTab to="policies" label="Policies" />
                </ShowIfAuthorized>
              </NavigationTabs>
              <Stack spacing={8}>
                <Outlet />
              </Stack>
            </Stack>
          </Box>
        }
        errorElement={<RouterErrorElement />}
      >
        <Route path="regions" element={<RegionsTable />} />
        <Route
          path="reserved-instances"
          element={
            <RequireAuthz
              requiredPermissions={[
                "core.remote_rendering.change_reserved_instances",
              ]}
            >
              <ReservedInstancesTable />
            </RequireAuthz>
          }
        />
        <Route
          path="virtual-machines"
          element={
            <RequireAuthz
              requiredPermissions={[
                "core.remote_rendering.list_virtual_machines",
              ]}
            >
              <VirtualMachinesTable />
            </RequireAuthz>
          }
        />
        <Route
          path="policies"
          element={
            <RequireAuthz
              requiredPermissions={["core.sessions.change_policies"]}
            >
              <CloudRenderingPolicies />
            </RequireAuthz>
          }
        />
      </Route>
    </SentryRoutes>
  );
}
