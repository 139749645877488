import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useAuthentication } from "../auth";
import { fetchUserGlobalPermissions } from "../backend/api";
import { GlobalPermissionString } from "../backend/types";
import {
  getQueryKeyForOrganization,
  useActiveOrganizationQuery,
} from "./useActiveOrganization";
import { getQueryKeyForUser } from "./useUserQuery";

export function useCurrentUserGlobalPermissionsQuery(
  options: Partial<UseQueryOptions<GlobalPermissionString[]>> = {},
) {
  const { user } = useAuthentication();
  const { data: organization } = useActiveOrganizationQuery();

  return useUserGlobalPermissionsQuery(user?.id, organization?.id, options);
}

export function useUserGlobalPermissionsQuery(
  userId: number | undefined,
  organizationId: number | undefined,
  options: Partial<UseQueryOptions<GlobalPermissionString[]>> = {},
) {
  return useQuery<GlobalPermissionString[]>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      ...getQueryKeyForOrganization(organizationId ?? null),
      ...getQueryKeyForUser(userId ?? "current").slice(0, 2),
      "global-permissions",
    ],
    queryFn: async () => {
      if (!userId) throw Error("User not found");
      if (!organizationId) throw Error("Organization not found");
      return fetchUserGlobalPermissions(userId, organizationId);
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!userId && !!organizationId,
    ...options,
  });
}
