import {
  Alert,
  AlertIcon,
  Box,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { MdError } from "react-icons/md";
import { FormSubmitButton } from "../../components";
import { ManageReservedInstanceGeneralTabForm } from "./ManageReservedInstanceGeneralTabForm";
import { ManageReservedInstanceSchedulingTabForm } from "./ManageReservedInstanceSchedulingTabForm";
import { UpdateReservedInstanceSchema } from "./reservedInstanceSchema";

export const ManageReservedInstanceForm = ({
  isCreate,
  onSubmit,
  form: {
    formState,
    control,
    handleSubmit,
    register,
    setValue,
    getValues,
    resetField,
    watch,
  },
  hasSaveError,
}: {
  isCreate: boolean;
  onSubmit: (values: UpdateReservedInstanceSchema) => void;
  form: UseFormReturn<UpdateReservedInstanceSchema>;
  hasSaveError: boolean;
}) => {
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Tabs>
            <TabList>
              <Tab>
                General{" "}
                {
                  <Box
                    hidden={
                      !formState.errors.organizationId &&
                      !formState.errors.region &&
                      !formState.errors.vmSize &&
                      !formState.errors.description &&
                      !formState.errors.instances
                    }
                  >
                    <Icon marginLeft={2} as={MdError} color="red" />
                  </Box>
                }
              </Tab>
              <Tab>
                Scheduling{" "}
                {
                  <Box
                    hidden={
                      !formState.errors.weekDaysBitmask &&
                      !formState.errors.timeZoneId &&
                      !formState.errors.toDate &&
                      !formState.errors.fromDate &&
                      !formState.errors.toTime &&
                      !formState.errors.fromTime
                    }
                  >
                    <Icon marginLeft={2} as={MdError} color="red" />
                  </Box>
                }
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel paddingX={0} paddingY={4}>
                <ManageReservedInstanceGeneralTabForm
                  isCreate={isCreate}
                  formState={formState}
                  control={control}
                  register={register}
                  setValue={setValue}
                  watch={watch}
                />
              </TabPanel>
              <TabPanel paddingX={0} paddingY={4}>
                <ManageReservedInstanceSchedulingTabForm
                  formState={formState}
                  control={control}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  resetField={resetField}
                  watch={watch}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
          <FormSubmitButton alignSelf={"end"} formState={formState} />
          <Alert status="error" hidden={!hasSaveError}>
            <AlertIcon />
            Could not save the reserved instance. Please try again later.
          </Alert>
        </Stack>
      </form>
    </>
  );
};
