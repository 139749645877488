import { useToast } from "@chakra-ui/react";
import { useCallback } from "react";
import { DevTool } from "../../hookform-devtools";
import { useCreateReservedInstanceMutation } from "../hooks/useCreateReservedInstanceMutation";
import { useReservedInstanceForm } from "../hooks/useReservedInstanceForm";
import { ManageReservedInstanceForm } from "./ManageReservedInstanceForm";
import { UpdateReservedInstanceSchema } from "./reservedInstanceSchema";
import { useActiveOrganizationQuery } from "../../hooks";

export const CreateReservedInstanceForm = ({
  onForwardSubmit,
}: {
  onForwardSubmit: () => void;
}) => {
  const toast = useToast();
  const createReservedInstance = useCreateReservedInstanceMutation({});
  const { data: organization } = useActiveOrganizationQuery();
  const form = useReservedInstanceForm({
    organizationId: organization?.id?.toString() ?? null,
  });

  const onSubmit = useCallback(
    (values: UpdateReservedInstanceSchema) =>
      createReservedInstance.mutateAsync(values, {
        onSuccess: () => {
          toast({
            title: "Created",
            description: `Created reserved instances.`,
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top-right",
          });
          onForwardSubmit();
        },
        onError: () => {
          toast({
            title: "Error",
            description: `Could not create reserved instances. Please try again later.`,
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "top-right",
          });
        },
      }),
    [createReservedInstance, onForwardSubmit, toast],
  );

  return (
    <>
      <ManageReservedInstanceForm
        isCreate={true}
        form={form}
        onSubmit={onSubmit}
        hasSaveError={createReservedInstance.isError}
      />
      <DevTool control={form.control} placement="bottom-left" />
    </>
  );
};
