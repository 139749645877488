import { useCallback, useMemo } from "react";
import { useAuthentication } from "../auth";
import { AllPermissionString, GlobalPermissionString } from "../backend/types";
import { useCurrentUserGlobalPermissionsQuery } from "./useUserGlobalPermissionsQuery";
import { useCurrentUserPlatformPermissionsQuery } from "./useUserPlatformPermissionsQuery";

export function useIsSuperuser() {
  const { user } = useAuthentication();

  return user?.is_superuser ?? false;
}

export function usePermissions() {
  const { data: platformPermissions, isLoading: platformPermissionsLoading } =
    useCurrentUserPlatformPermissionsQuery({
      refetchOnWindowFocus: true,
    });
  const { data: globalPermissions, isLoading: globalPermissionsLoading } =
    useCurrentUserGlobalPermissionsQuery({
      refetchOnWindowFocus: true,
    });
  const isLoading = globalPermissionsLoading || platformPermissionsLoading;

  const permissions = useMemo(
    () => [...(globalPermissions ?? []), ...(platformPermissions ?? [])],
    [globalPermissions, platformPermissions],
  );

  const hasPermission = useCallback(
    (perm: AllPermissionString): boolean =>
      permissions?.includes(perm) ?? false,
    [permissions],
  );

  const hasGlobalPermission = useCallback(
    (perm: GlobalPermissionString): boolean =>
      globalPermissions?.includes(perm) ?? false,
    [globalPermissions],
  );

  const hasPermissions = useCallback(
    (perms: AllPermissionString[]): boolean =>
      perms.every((perm) => hasPermission(perm)),
    [hasPermission],
  );

  const hasSomePermission = useCallback(
    (perms: AllPermissionString[]): boolean =>
      perms.some((perm) => hasPermission(perm)),
    [hasPermission],
  );

  const isSuperuser = useIsSuperuser();

  return useMemo(
    () => ({
      is_superuser: isSuperuser,
      change_organization: hasPermission("core.change_organization"),
      change_user: hasPermission("core.change_user"),
      change_usergroup: hasPermission("core.change_usergroup"),
      view_user: hasPermission("core.view_user"),
      view_usergroup: hasPermission("core.view_usergroup"),
      add_application: hasPermission("assets.add_application"),
      change_fixed_ip: hasPermission("core.remote_rendering.change_fixed_ips"),
      view_statistics: hasPermission("core.sessions.view_statistics"),
      can_change_global_reserved_instances: hasPermission(
        "core.remote_rendering.change_global_reserved_instances",
      ),
      list_global_virtual_machines: hasGlobalPermission(
        "core.remote_rendering.list_virtual_machines",
      ),
      hasGlobalPermission,
      hasPermission,
      hasPermissions,
      hasSomePermission,
      isLoading,
    }),
    [
      hasGlobalPermission,
      hasPermission,
      hasPermissions,
      hasSomePermission,
      isLoading,
      isSuperuser,
    ],
  );
}
