import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { FixedIp, getFixedIps } from "../../session-management";

export function getFixedIpsQueryKey(region?: string) {
  return region ? ["fixed-ips", region] : ["fixed-ips"];
}

export function useFixedIpsQuery(
  region?: string,
  options?: Partial<UseQueryOptions<FixedIp[]>>,
) {
  return useQuery({
    queryKey: getFixedIpsQueryKey(region),
    queryFn: () => getFixedIps(region),
    retry: false,
    throwOnError: true,
    ...options,
  });
}
