import { PropsWithChildren } from "react";
import { PermissionString } from "../backend/types";
import { AuthorizingAlert, FullPageCenteredContainer } from "../components";
import { HttpErrorFallback } from "../components/ErrorFallback";
import { usePermissions } from "../hooks/usePermissions";

export function RequireAuthz({
  children,
  requiredPermissions = [],
  requireAnyPermissions = [],
}: PropsWithChildren & {
  requiredPermissions?: PermissionString[];
  requireAnyPermissions?: PermissionString[];
}): JSX.Element {
  const { hasPermissions, hasSomePermission, isLoading } = usePermissions();

  // if we are currently checking the user's permissions, wait for it to finish before doing anything
  if (isLoading) {
    return (
      <FullPageCenteredContainer>
        <AuthorizingAlert />
      </FullPageCenteredContainer>
    );
  }

  if (requiredPermissions.length > 0 && !hasPermissions(requiredPermissions)) {
    return <HttpErrorFallback statusCode={403} message="Access Denied" />;
  }

  if (
    requireAnyPermissions.length > 0 &&
    !hasSomePermission(requireAnyPermissions)
  ) {
    return <HttpErrorFallback statusCode={403} message="Access Denied" />;
  }

  // all good, render children
  return <>{children}</>;
}
