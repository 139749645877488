import { InferType } from "yup";
import { applicationBuildMetadataSchema } from "./schema";

export type PortalBackendPaginatedResult<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
};

export enum ApplicationStatus {
  Pending = 0,
  InvalidPath = 1,
  NoExecutables = 2,
  MultipleExecutables = 3,
  Valid = 5,
  InvalidApplicationArchive = 7,
}

export const knownTargetPlatforms = ["windows", "android", "unknown"] as const;

export type TargetPlatform = (typeof knownTargetPlatforms)[number];

export const knownLegacyXRPlatforms = [
  "none",
  "openvr",
  "openxr",
  "oculus_quest",
  "oculus_rift",
  "wave",
  "pico",
] as const;

/**
 * @deprecated Legacy XR Platforms will be removed in the future
 */
type LegacyXRPlatform = (typeof knownLegacyXRPlatforms)[number];

export type Application = {
  id: UUID;
  legacy_identity: string;
  oauth2_client_id: string | null;
  tags: string[];
  description_html: string;
  description: string;
  name: string;
  images: ApplicationImageDetails[];
  panoramic_preview_image: string | null;
  modified_date: string;
  external_references?: ApplicationExternalReference[];
  organizations: Organization["id"][];
  launch_configurations: Omit<ApplicationLaunchConfiguration, "application">[];
  permissions: {
    [perm in ApplicationPermission]: boolean;
  };
};

export type PaginatedApplicationsList =
  PortalBackendPaginatedResult<Application>;

/**
 * @deprecated Legacy Application Versions will be removed in the future
 */
export type LegacyApplicationVersion = Omit<
  ApplicationBuild,
  "supported_xr_platforms"
> & {
  identity: string;
  application: ApplicationId;
  supported_xr_platforms: LegacyXRPlatform[];
  organizations: Organization["id"][];
  current_version: boolean;
  permissions: {
    [perm in ApplicationPermission]: boolean;
  };
} & Omit<Application, "current_version" | "id">;

export type PaginatedLegacyApplicationVersionsList =
  PortalBackendPaginatedResult<LegacyApplicationVersion>;

export type ApplicationBuild = {
  id: number;
  application: ApplicationId;
  version: string;
  supported_xr_platforms: XRPlatformType[];
  metadata: InferType<typeof applicationBuildMetadataSchema>;
  is_deprecated: boolean;
  status: ApplicationStatus;
  supports_arbitrary_cli_args: boolean;
  launch_args: string | null;
  changelog: string;
  target_platform: TargetPlatform;
  executable_path: string | null;
  application_archive: string;
  optimized_application_archive: string | null;
  package_name: string | null;
  created_date: string;
};

export type PaginatedApplicationBuildsList =
  PortalBackendPaginatedResult<ApplicationBuild>;

export type ApplicationCategory = {
  id: number;
  name: string;
  order: number;
  applications: ApplicationId[];
};

export type PaginatedApplicationCategoriesList =
  PortalBackendPaginatedResult<ApplicationCategory>;

export enum NetworkSecurityRuleDirection {
  Outbound = 1,
  Inbound = 0,
}

export const supportedNetworkProtocols = ["any", "tcp", "udp"] as const;

export type NetworkProtocol = (typeof supportedNetworkProtocols)[number];

export type NetworkSecurityRule = {
  cidr_blocks: string[];
  from_port: number;
  to_port: number;
  protocol: NetworkProtocol;
  direction: NetworkSecurityRuleDirection;
  description: string;
};

export type ApplicationNetworkSecurityRule = NetworkSecurityRule & {
  id: number;
  // this right now is the id of an application but in lieu of an API to work with application ids, we need to ignore this
  application: unknown;
};

export type PaginatedApplicationNetworkSecurityRulesList =
  PortalBackendPaginatedResult<ApplicationNetworkSecurityRule>;

export type ApplicationUrlRewriteRule = {
  name: string;
  description: string | null;
  match: string;
  replace: string;
  enabled: boolean;
  isPreset: boolean;
};

export type ApplicationUrlRewriteConfiguration = {
  rules?: Array<Partial<ApplicationUrlRewriteRule>>;
};

export type ApplicationCloudRenderingConfiguration = {
  vm_size: string | null;
  url_rewrites: ApplicationUrlRewriteConfiguration | null;
};

export type PaginatedUsersList = PortalBackendPaginatedResult<User>;

export type PaginatedUserIdsList = PortalBackendPaginatedResult<number>;

export type PaginationFilters = {
  page?: number;
  page_size?: number;
};

export type OrderingFilters = {
  ordering?: string;
};

export type LegacyApplicationVersionListFilters = PaginationFilters & {
  supports_vr?: boolean;
  supports_non_vr?: boolean;
  id_in?: number[];
  organization?: number;
  ordering?: string;
  search?: string;
  fulltext_search?: string;
  status?: number;
  target_platform?: TargetPlatform;
};

export type ApplicationBuildListFilters = PaginationFilters &
  OrderingFilters & {
    supported_xr_platform?: XRPlatformType | XRPlatformType[];
    application?: ApplicationId;
  };

export type ApplicationListFilters = PaginationFilters &
  OrderingFilters & {
    fulltext_search?: string;
    id_in?: ApplicationId[];
    organization?: number;
    supported_xr_platform?: XRPlatformType | XRPlatformType[];
    permission?: ApplicationPermission;
  };

export type ApplicationCategoryFilters = PaginationFilters & {
  with_application?: ApplicationId;
};

export type UserGroup = {
  id: number;
  name: string;
  organization: number;
};

export type PortalUserGroupsList = PortalBackendPaginatedResult<UserGroup>;

export type Organization = {
  id: number;
  name: string;
  subdomain: string;
  domain: string;
  active_auth_provider: number | null;
  authorization_endpoint: string;
};

export type User = {
  id: number;
  email: string;
  full_name: string;
  first_name: string;
  last_name: string;
  last_login: string;
  is_superuser: boolean;
  is_active: boolean;
  organizations: Organization[];
  groups: UserGroup[];
};

const applicationPermissions = [
  "view",
  "stream",
  "download",
  "change",
] as const;

export type ApplicationPermission = (typeof applicationPermissions)[number];

export type BaseApplicationPermission = {
  permissions: ApplicationPermission[];
  organization: number;
  valid_until: string | null;
};

export type GrantedApplicationPermission = {
  application: ApplicationId;
} & BaseApplicationPermission;

export type UserApplicationPermission = {
  user: number;
} & BaseApplicationPermission;

export type UserGroupApplicationPermission = {
  group: number;
} & BaseApplicationPermission;

export type ApplicationImageDetails = {
  id: number;
  image: string;
};

export type ValidationErrorResponse = {
  [fieldName: string]: string[];
};

export type PermissionString =
  | "core.change_organization"
  | "core.view_user"
  | "core.change_user"
  | "core.view_usergroup"
  | "core.change_usergroup"
  | "assets.stream_application"
  | "assets.download_application"
  | "assets.add_application"
  | "assets.add_applicationcategory"
  | "core.browse_applications"
  | "core.remote_rendering.change_reserved_instances"
  | "core.remote_rendering.change_fixed_ips"
  | "core.remote_rendering.use_unamanged_regions"
  | "core.remote_rendering.use_dcv"
  | "core.remote_rendering.list_virtual_machines"
  | "core.sessions.list_sessions"
  | "core.sessions.change_policies"
  | "core.sessions.view_statistics";

export type GlobalPermissionString =
  | "core.remote_rendering.can_debug"
  | "core.remote_rendering.list_virtual_machines"
  | "core.remote_rendering.change_global_reserved_instances"
  | "core.consumption.manage_consumption";

export type AllPermissionString = PermissionString | GlobalPermissionString;

export type PlatformPermissionInfo = {
  permission: PermissionString;
  category: string;
  description: string;
};

export type PortalError = {
  message?: string;
  detail?: string;
  [key: string]: undefined | string | string[];
};

export type InvitationInfo = {
  id: string;
  user: string;
  organization: number;
  created_at: string;
  created_by: number | null;
  accepted_at: string | null;
  groups: UserGroup[];
};

export type ErrorResponse = {
  detail: string;
};

export type BrandingResponseData = {
  primary: string | null;
  background: string | null;
  organization: string | null;
  logo: string | null;
  app_image_placeholder: string | null;
  product_icon: string | null;
  company_name: string;
  product_name: string;
};

export type PermissionSubject = Pick<User | UserGroup, "id"> &
  Partial<User | UserGroup> & {
    type: "user" | "group";
  };

export type UUID = string;

export type ApplicationId = Application["id"];

export type ApplicationBuildId = ApplicationBuild["id"];

export type ApplicationExternalReference = {
  type: string;
  url: string;
  is_active: boolean;
};

export const xrPlatformTypes = [
  "quest",
  "wave",
  "pico",
  "vrx",
  "win-vr",
  "win-non-vr",
] as const;

export type XRPlatformType = (typeof xrPlatformTypes)[number];

export const windowsXRPlatformTypes: XRPlatformType[] = [
  "win-vr",
  "win-non-vr",
];

export const androidXRPlatformTypes: XRPlatformType[] = [
  "quest",
  "wave",
  "pico",
  "vrx",
];

export type ApplicationLaunchConfiguration = {
  application: ApplicationId;
  application_build: ApplicationBuildId | null;
  xr_platform: XRPlatformType;
};
