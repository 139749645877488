import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
  getReservedInstances,
  ReservedInstance,
} from "../../session-management";
import { ReservedInstancesFilter } from "../../session-management/types";

export function getReservedInstancesQueryKey(filter?: ReservedInstancesFilter) {
  return ["reserved-instances", filter?.organizationId];
}

export function useReservedInstancesQuery(
  filter: ReservedInstancesFilter,
  options: Partial<UseQueryOptions<ReservedInstance[]>> = {},
) {
  return useQuery({
    queryKey: getReservedInstancesQueryKey(filter),
    queryFn: () => getReservedInstances(filter),
    retry: false,
    throwOnError: true,
    ...options,
  });
}
