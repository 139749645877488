import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getVirtualMachines, VmFilter } from "../../session-management";
import { VirtualMachineList } from "../../session-management/types";

export function getVirtualMachinesQueryKey(params: VmFilter = {}) {
  return ["virtualMachines", params];
}

export function useVirtualMachinesQuery(
  params: VmFilter = {},
  options: Partial<UseQueryOptions<VirtualMachineList>> = {},
) {
  return useQuery({
    queryKey: getVirtualMachinesQueryKey(params),
    queryFn: () => getVirtualMachines(params),
    refetchOnMount: false,
    staleTime: 1800,
    retry: false,
    ...options,
  });
}
