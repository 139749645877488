import { PropsWithChildren, ReactNode } from "react";
import {
  AllPermissionString
} from "../backend/types";
import { usePermissions } from "../hooks/usePermissions";

export function ShowIfAuthorized({
  children,
  requiredPermissions,
}: PropsWithChildren & {
  requiredPermissions: AllPermissionString[];
}): ReactNode {
  const { hasPermissions } = usePermissions();
  if (!hasPermissions(requiredPermissions)) {
    return null;
  }

  // all good, render children
  return children;
}
