import { Image, ImageProps } from "@chakra-ui/react";
import { useMemo } from "react";
import { Application } from "../../backend";
import { useBrandingQuery } from "../../hooks";
import placeholderImage from "../../img/app_placeholder.png";

export function ApplicationPreviewImage({
  application,
  ...props
}: {
  application: Pick<Application, "images" | "panoramic_preview_image">;
} & ImageProps) {
  const { data: branding } = useBrandingQuery();

  const imageUrl = useMemo(() => {
    let imageUrl;
    // check if there are any preview images and take the first if any
    if (application.images?.length) {
      imageUrl = application.images?.[0]?.image;
    }

    // if no preview image is available, attempt to use panoramic preview image
    if (application.panoramic_preview_image) {
      imageUrl = application.panoramic_preview_image;
    }

    // if none of the above match, use the fallback image from branding
    if (!imageUrl && branding?.app_image_placeholder) {
      imageUrl = branding?.app_image_placeholder;
    }

    if (imageUrl) {
      imageUrl += "?width=200";
    }

    return imageUrl;
  }, [
    application.images,
    application.panoramic_preview_image,
    branding?.app_image_placeholder,
  ]);

  return (
    <Image
      width={24}
      height={14}
      objectFit="cover"
      borderRadius="md"
      src={imageUrl}
      fallbackSrc={placeholderImage}
      maxWidth={"unset"}
      {...props}
    />
  );
}
