import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Tag,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  FaPlus as AddIcon,
  FaRegDotCircle as SessionActiveIcon,
} from "react-icons/fa";
import {
  AddButton,
  DeleteButton,
  OptionsButton,
  PaginatedTable,
} from "../../components";
import { EditButton } from "../../components/EditButton";
import { MotionIcon } from "../../components/MotionIcon";
import { NoData } from "../../components/NoData";
import { RemoteRenderingRegionVisualization } from "../../components/SessionRenderingVisualization";
import { useConfirm } from "../../confirm-dialog";
import { usePermissions } from "../../hooks/usePermissions";
import {
  FullWeekDaysBitMask,
  ReservedInstance,
  WeekDay,
  WorkWeekDaysBitMask,
  isBit,
} from "../../session-management";
import { useCloudRenderingRegionsQuery } from "../hooks/useCloudRenderingRegionsQuery";
import { useDeleteReservedInstanceMutation } from "../hooks/useDeleteReservedInstanceMutation";
import { useReservedInstancesQuery } from "../hooks/useReservedInstancesQuery";
import { CreateReservedInstanceForm } from "./CreateReservedInstanceForm";
import { EditReservedInstanceForm } from "./EditReservedInstanceForm";
import { useActiveOrganizationQuery } from "../../hooks";

const WeekDayComponent = ({
  weekDaysBitmask,
}: {
  weekDaysBitmask?: number | null;
}) => {
  const weekDays = [];

  if (weekDaysBitmask === WorkWeekDaysBitMask)
    weekDays.push(<Tag key={0}>Mo-Fr</Tag>);
  else if (
    weekDaysBitmask === null ||
    weekDaysBitmask === undefined ||
    weekDaysBitmask === FullWeekDaysBitMask
  ) {
    weekDays.push(<Tag key={0}>Mo-Su</Tag>);
  } else {
    Object.values(WeekDay).forEach((r, idx) => {
      if (isBit(weekDaysBitmask ?? 0, idx)) {
        weekDays.push(
          <Tag padding={1} margin={0} key={idx}>
            {r.slice(0, 2)}
          </Tag>,
        );
      }
    });
  }

  return <HStack>{weekDays}</HStack>;
};

const columnHelper = createColumnHelper<ReservedInstance>();

const ReservedInstancesTableContainer = ({
  reservedInstancesQuery,
  onOpen,
  setEditedReservedInstance,
}: {
  reservedInstancesQuery: UseQueryResult<ReservedInstance[], unknown>;
  onOpen: () => void;
  setEditedReservedInstance: React.Dispatch<
    React.SetStateAction<ReservedInstance | undefined>
  >;
}) => {
  const { data: regions } = useCloudRenderingRegionsQuery({
    includeDisabledRegions: true,
  });
  const toast = useToast();
  const { confirm } = useConfirm();
  const permissions = usePermissions();

  const { mutateAsync } = useDeleteReservedInstanceMutation({
    onSuccess: () => {
      toast({
        title: "Deleted",
        description: `Deleted reserved instance.`,
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    },
    onError: () => {
      toast({
        title: "Error",
        description: `Could not delete reserved instance. Please try again later.`,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    },
  });

  const deleteReservedInstance = useCallback(
    (reservedInstance: ReservedInstance) =>
      confirm({
        title: `Delete reserved instance`,
        body: (
          <Stack>
            <Text>
              You are about to delete <i>{reservedInstance.instances}</i>{" "}
              instance reservation{reservedInstance.instances > 1 ? "s" : ""} in
              region <b>{reservedInstance.regionDisplayName}</b> for VM size{" "}
              <b>{reservedInstance.vmSizeDisplayName}</b>.
            </Text>
            <Text>This action cannot be undone!</Text>
          </Stack>
        ),
        confirmButtonText: "Delete",
        onConfirm: () => mutateAsync(reservedInstance.id),
      }),
    [confirm, mutateAsync],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("isCurrentlyActive", {
        header: "State",
        cell: (props) => (
          <>
            {props.row.original.isCurrentlyActive ? (
              <>
                <HStack>
                  <MotionIcon
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 0.4 }}
                    transition={{
                      repeat: Infinity,
                      repeatType: "reverse",
                      duration: 1,
                    }}
                    color="red.400"
                    as={SessionActiveIcon}
                  />{" "}
                  <Text>Active</Text>
                </HStack>
              </>
            ) : (
              <HStack>
                <Icon as={SessionActiveIcon} color="black.400" opacity="0.2" />
                <Text>Inactive</Text>
              </HStack>
            )}
          </>
        ),
      }),
      columnHelper.display({
        header: "Scope",
        cell: (props) =>
          props.row.original.organizationId ? (
            <Tag>org</Tag>
          ) : (
            <Tag>global</Tag>
          ),
      }),
      columnHelper.accessor("regionDisplayName", {
        header: "Region",
        cell: (props) => (
          <Box>
            <RemoteRenderingRegionVisualization
              regionName={props.row.original.region}
            />
          </Box>
        ),
      }),
      columnHelper.accessor("vmSizeDisplayName", {
        header: "Vm Size",
      }),
      columnHelper.accessor("instances", {
        header: "Count",
      }),
      columnHelper.accessor("type", {
        header: "Type",
      }),
      columnHelper.accessor("weekDaysBitmask", {
        header: "Week Days",
        cell: (props) => (
          <WeekDayComponent
            weekDaysBitmask={props.row.original.weekDaysBitmask}
          />
        ),
      }),
      columnHelper.display({
        header: "Time",
        cell: (props) => (
          <>
            {props.row.original.fromTime && props.row.original.toTime
              ? props.row.original.fromTime.slice(0, 5) +
                " to " +
                props.row.original.toTime.slice(0, 5)
              : "always on"}
          </>
        ),
      }),
      columnHelper.display({
        header: "Date Limitation",
        cell: (props) => (
          <>
            {props.row.original.fromDate && props.row.original.toDate
              ? props.row.original.fromDate.slice(0, 10) +
                " to " +
                props.row.original.toDate.slice(0, 10)
              : "any"}
          </>
        ),
      }),
      columnHelper.accessor("description", {
        header: "Description",
        cell: (props) => (
          <Text textOverflow="ellipsis" overflow="hidden" maxW={24}>
            {props.row.original.description}
          </Text>
        ),
      }),
      columnHelper.display({
        id: "options",
        cell: (props) => (
          <Flex justifyContent={"end"}>
            <OptionsButton
              label="Click on this button to display user actions"
              isDisabled={
                !props.row.original.organizationId &&
                !permissions.can_change_global_reserved_instances
              }
            >
              <EditButton
                variant="ghost"
                width="full"
                isDisabled={
                  !regions?.find((r) => r.name === props.row.original.region)
                    ?.isEnabled
                }
                onClick={() => {
                  setEditedReservedInstance(props.row.original);
                  onOpen();
                }}
                fontWeight="normal"
                fontSize="sm"
              >
                Edit reserved instance
              </EditButton>
              <DeleteButton
                onClick={() => deleteReservedInstance(props.row.original)}
              >
                Delete reserved instance
              </DeleteButton>
            </OptionsButton>
          </Flex>
        ),
        header: () => <Flex justifyContent={"end"}>Options</Flex>,
      }),
    ],
    [
      deleteReservedInstance,
      onOpen,
      regions,
      setEditedReservedInstance,
      permissions.can_change_global_reserved_instances,
    ],
  );

  const table = useReactTable({
    columns: useMemo(
      () =>
        reservedInstancesQuery.isLoading
          ? columns.map((col, idx) => ({
              ...col,
              cell: () => <Skeleton key={idx}>Loading</Skeleton>,
            }))
          : columns,
      [columns, reservedInstancesQuery.isLoading],
    ),
    data: useMemo(
      () =>
        reservedInstancesQuery.isLoading
          ? Array(5).fill({} as ReservedInstance)
          : [...(reservedInstancesQuery.data ?? [])],
      [reservedInstancesQuery.data, reservedInstancesQuery.isLoading],
    ),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
      columnOrder: ["isCurrentlyActive"],
    },
    autoResetPageIndex: true,
  });

  return table;
};

export const ReservedInstancesTable = () => {
  const toast = useToast();
  const { hasGlobalPermission } = usePermissions();
  const { data: organization, isSuccess: isOrganizationQuerySuccess } =
    useActiveOrganizationQuery();

  const reservedInstancesQuery = useReservedInstancesQuery(
    {
      organizationId: hasGlobalPermission(
        "core.remote_rendering.change_global_reserved_instances",
      )
        ? undefined
        : organization?.id?.toString(),
    },
    {
      enabled: isOrganizationQuerySuccess,
    },
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editedReservedInstance, setEditedReservedInstance] = useState<
    ReservedInstance | undefined
  >();

  useEffect(() => {
    if (reservedInstancesQuery.isError) {
      toast({
        title: "Could not load reserved instances.",
        description: `Could not load reserved instances. Please try again later.`,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [reservedInstancesQuery, toast]);

  const table = ReservedInstancesTableContainer({
    reservedInstancesQuery: reservedInstancesQuery,
    onOpen: onOpen,
    setEditedReservedInstance: setEditedReservedInstance,
  });

  return (
    <>
      <Stack>
        <HStack>
          <Text>
            Configure reserved instances. Reserved instances help ensuring
            availability of cloud rendering machine on a given time. Reserved
            instances also help improve performance for Local Zones and
            Wavelength Zones.
          </Text>
          <Button
            leftIcon={<Icon boxSize={3} as={AddIcon} />}
            colorScheme="brand"
            onClick={async () => {
              setEditedReservedInstance(undefined);
              onOpen();
            }}
            flexShrink={0}
          >
            Add
          </Button>
        </HStack>
        {reservedInstancesQuery.data && !reservedInstancesQuery.data.length ? (
          <NoData
            text="Looks like there are no reserved instances (yet). Try adding
          some."
            callToAction={
              <AddButton
                onClick={() => {
                  setEditedReservedInstance(undefined);
                  onOpen();
                }}
              >
                Add Reserved Instance
              </AddButton>
            }
          />
        ) : (
          <PaginatedTable table={table} />
        )}
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          {editedReservedInstance && (
            <ModalHeader>Edit Reserved Instance</ModalHeader>
          )}
          {!editedReservedInstance && (
            <ModalHeader>Create Reserved Instance</ModalHeader>
          )}
          <ModalCloseButton />
          <ModalBody>
            {!editedReservedInstance && (
              <CreateReservedInstanceForm onForwardSubmit={() => onClose()} />
            )}
            {editedReservedInstance && (
              <EditReservedInstanceForm
                reservedInstance={editedReservedInstance}
                onForwardSubmit={() => onClose()}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
