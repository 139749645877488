import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Divider,
  Heading,
  HStack,
  Skeleton,
  Spacer,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { generatePath, Outlet, useNavigate, useParams } from "react-router-dom";
import {
  DeleteButton,
  LinkButton,
  NavigationTab,
  NavigationTabs,
  OptionsButton,
  PageTitle,
} from "../components";
import { UnauthorizedImage } from "../components/ErrorFallback";
import { useApplicationCapabilities, useApplicationQuery } from "../hooks";
import { useApplicationBuildQuery } from "../hooks/useApplicationBuildsQuery";
import { namedRoutes } from "../routes";
import { CapabilityResultType } from "./ApplicationCapability";
import { Provider } from "./ApplicationDetailsContext";
import { ViewApplicationOnPortalButton } from "./components/ViewApplicationOnPortalButton";
import { useApplicationDeleteConfirm } from "./hooks/useApplicationDeleteConfirm";
import { ShowIfAuthorized } from "../auth/ShowIfAuthorized";

export function ApplicationDetails() {
  const navigate = useNavigate();
  const { applicationId = "" } = useParams();

  const isApplicationBuildId = !isNaN(Number(applicationId));

  // if application Id is a number, it's actually an application build's id, not an application id which would be a UUID
  // in this case, we need to find the underlying application id
  const applicationBuildQuery = useApplicationBuildQuery(
    Number(applicationId),
    {
      retry: false,
      throwOnError: true,
      enabled: isApplicationBuildId,
    },
  );

  useEffect(() => {
    applicationBuildQuery.data &&
      navigate(
        generatePath(namedRoutes.application.overview, {
          applicationId: applicationBuildQuery.data.application,
        }),
      );
  }, [applicationBuildQuery.data, navigate]);

  const applicationQuery = useApplicationQuery(
    isApplicationBuildId
      ? (applicationBuildQuery.data?.application ?? "")
      : applicationId,
    {
      retry: false,
      throwOnError: true,
      enabled: !isApplicationBuildId || applicationBuildQuery.isSuccess,
    },
  );
  const confirmApplicationDeletion = useApplicationDeleteConfirm();
  const applicationCapabilities = useApplicationCapabilities(
    applicationQuery.data,
  );

  if (applicationQuery.data?.permissions.change === false) {
    return (
      <VStack role="alert" paddingY={10}>
        <VStack spacing={4}>
          <UnauthorizedImage />
          <Heading>Forbidden</Heading>
          <VStack width="md" textAlign={"center"}>
            <Text>
              You do not have permission to manage the details of{" "}
              <b>{applicationQuery.data?.name}</b>.
            </Text>
            <Text color="GrayText">
              If you think this is an error, please reach out to an
              administrator.
            </Text>
          </VStack>
          <Spacer />
          <LinkButton
            to={namedRoutes.application.list}
            colorScheme="brand"
            leftIcon={<ArrowBackIcon />}
          >
            Go back to the application list
          </LinkButton>
        </VStack>
      </VStack>
    );
  }

  return (
    <>
      <PageTitle
        title={
          applicationQuery.isLoading
            ? "Loading ..."
            : "Application: " +
              (applicationQuery.data?.name ?? "Unknown application")
        }
      />
      <Stack spacing={6}>
        <HStack>
          <Skeleton isLoaded={applicationQuery.isSuccess}>
            <Heading>{applicationQuery.data?.name}&apos;s details</Heading>
          </Skeleton>
          <Spacer />
          <OptionsButton label="Click on this button to display user actions">
            {applicationQuery.data && (
              <ViewApplicationOnPortalButton
                application={applicationQuery.data}
              />
            )}
            <Divider />
            <DeleteButton
              onClick={
                applicationQuery.data &&
                confirmApplicationDeletion(applicationQuery.data)
              }
            >
              Delete Application
            </DeleteButton>
          </OptionsButton>
        </HStack>
        <NavigationTabs>
          <NavigationTab
            to={generatePath(namedRoutes.application.overview, {
              applicationId,
            })}
            label="Overview"
          />
          <NavigationTab
            to={generatePath(namedRoutes.application.builds, {
              applicationId,
            })}
            label="Builds"
          />
          {applicationCapabilities.find(
            (capability) =>
              capability.type === "cloud-rendering" &&
              capability.result === CapabilityResultType.Yes,
          ) && (
            <NavigationTab
              to={generatePath(namedRoutes.application.cloudRendering, {
                applicationId,
              })}
              label="Cloud Rendering"
            />
          )}
          <NavigationTab
            to={generatePath(namedRoutes.application.integrations, {
              applicationId,
            })}
            label="Integrations"
          />
          <NavigationTab
            to={generatePath(namedRoutes.application.categorization, {
              applicationId,
            })}
            label="Categorization"
          />
          <ShowIfAuthorized
            requiredPermissions={["core.sessions.list_sessions"]}
          >
            <NavigationTab
              to={generatePath(namedRoutes.application.activity, {
                applicationId,
              })}
              label="Activity"
            />
          </ShowIfAuthorized>
          <NavigationTab
            to={generatePath(namedRoutes.application.access, { applicationId })}
            label="Access Control"
          />
        </NavigationTabs>
        {applicationQuery.data ? (
          <Provider
            value={{
              application: applicationQuery.data,
            }}
          >
            <Outlet />
          </Provider>
        ) : (
          <Spinner />
        )}
      </Stack>
    </>
  );
}
