import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { PermissionString } from "../backend/types";
import { AuthorizingAlert, FullPageCenteredContainer } from "../components";
import { usePermissions } from "../hooks/usePermissions";
import { namedRoutes } from "../routes";

const anyControlPanelPermission: PermissionString[] = [
  "core.change_organization",
  "core.view_user",
  "core.view_usergroup",
  "core.change_usergroup",
  "core.view_usergroup",
  "assets.add_application",
  "assets.add_application",
  "assets.add_applicationcategory",
  "core.remote_rendering.change_reserved_instances",
  "core.remote_rendering.change_fixed_ips",
  "core.remote_rendering.use_unamanged_regions",
  "core.remote_rendering.use_dcv",
  "core.remote_rendering.list_virtual_machines",
  "core.sessions.list_sessions",
  "core.sessions.change_policies",
  "core.sessions.view_statistics",
];

export function RequireAnyControlPanelPermission({
  children,
}: PropsWithChildren): JSX.Element {
  const { hasSomePermission, isLoading } = usePermissions();

  // if we are currently checking the user's permissions, wait for it to finish before doing anything
  if (isLoading) {
    return (
      <FullPageCenteredContainer>
        <AuthorizingAlert />
      </FullPageCenteredContainer>
    );
  }

  // if the user has no access to the control panel, point him to the frontend instead
  if (!hasSomePermission(anyControlPanelPermission)) {
    return <Navigate to={namedRoutes.accessDenied} />;
  }

  return <>{children}</>;
}
